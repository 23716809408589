'use client';

import type { ReactNode } from 'react';

import PlusIcon from '@public/icons/plus-icon.svg';
import MinusIcon from '@public/icons/minus-icon.svg';
import Collapser from '../Collapser';
import { cn } from '@/helpers/common';
import { useIntersection } from '@/contexts/IntersectionProvider';

const CollapserKG = ({
  elementHash,
  collapsedByDefault,
  title,
  text,
}: {
  elementHash: string;
  title: string;
  text: string | ReactNode;
  collapsedByDefault: boolean;
}) => {
  const { addElementToArray } = useIntersection();

  return (
    <Collapser
      collapsed={collapsedByDefault}
      tag="section"
      ref={addElementToArray}
      id={elementHash}
      key={title}
      className={'mb-2'}
      visibleContent={({ collapse, collapsed }) => (
        <div
          onClick={collapse}
          className="flex justify-between gap-3 p-6 cursor-pointer hover:bg-athens-gray"
        >
          <div className="flex items-center">
            <p className="text-main-text-color">{title}</p>
          </div>

          <button
            className={cn(
              collapsed ? 'rotate-180' : 'rotate-0 max-410:pl-[10px]',
              'transition-transform duration-150 flex justify-center items-center [&_path]:hover:fill-black [&_path]:hover:stroke-black',
            )}
          >
            {collapsed ? <PlusIcon className="h-5 w-5" /> : <MinusIcon className="h-0.5 w-5" />}
          </button>
        </div>
      )}
    >
      {({ collapsed }) =>
        collapsed ||
        (typeof text === 'string' ? (
          <p
            dangerouslySetInnerHTML={{ __html: text as string }}
            className={
              'mt-1 mx-5 pb-4 leading-[25.6px] text-page-text-color2 whitespace-pre-line border-b-2 border-main-text-color'
            }
          />
        ) : (
          <p className="mt-1 mx-5 pb-4 leading-[25.6px] text-page-text-color2 whitespace-pre-line border-b-2 border-main-text-color">
            {text}
          </p>
        ))
      }
    </Collapser>
  );
};

export default CollapserKG;
