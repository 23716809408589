'use client';
import { RefObject, useCallback, useEffect, useRef } from 'react';

const useIntersectionObserver = (
  cb?: (element: HTMLElement) => void,
  options?: IntersectionObserverInit | undefined,
) => {
  const rootRef = useRef<Omit<HTMLElement | RefObject<HTMLElement>, 'ref'> | null>(null);
  // map is used here in case to avoid accidental duplication of elements;
  const elementsMapRef = useRef<
    Map<HTMLElement | RefObject<HTMLElement>, HTMLElement | RefObject<HTMLElement>>
  >(new Map());
  const observerRef = useRef<IntersectionObserver | null>(null);

  const addElementToArray = useCallback(
    <T extends HTMLElement | RefObject<HTMLElement>>(element: T) => {
      elementsMapRef.current.set(element, element);
    },
    [],
  );

  const addRootElement = useCallback(
    <T extends HTMLElement | RefObject<HTMLElement>>(element: T) => {
      rootRef.current = element;
    },
    [],
  );

  useEffect(() => {
    const { rootMargin = '0px', ...rest } = options ?? {};
    const marginArray = rootMargin.split(/\s+/).map((value, index) => {
      if (value.endsWith('px')) {
        return value;
      }

      if (value.endsWith('%')) {
        const number = parseInt(value);

        if (index === 0 || index === 2) {
          return (window.innerHeight / 100) * number + 'px';
        } else {
          return (window.innerWidth / 100) * number + 'px';
        }
      }
    });

    observerRef.current = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            cb?.(entry.target as HTMLElement);
          }
        });
      },
      {
        root: rootRef.current as Element,
        rootMargin: marginArray.join(' '),
        ...rest,
      },
    );

    elementsMapRef.current.forEach((element) => {
      if (observerRef.current && element instanceof HTMLElement) {
        observerRef.current.observe(element);
      }
    });

    return () => {
      observerRef.current?.disconnect();
    };
  }, [options, cb]);

  return { addElementToArray, addRootElement };
};

export default useIntersectionObserver;
