'use client';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { ReactNode, createContext, useContext } from 'react';

export const IntersectionContext = createContext<ReturnType<typeof useIntersectionObserver>>({
  addElementToArray: () => {},
  addRootElement: () => {},
});

interface IIntersectionProviderProps {
  children: ReactNode;
  cb?: (element: HTMLElement) => void;
  options?: IntersectionObserverInit | undefined;
}

export const IntersectionProvider = ({ cb, options, children }: IIntersectionProviderProps) => {
  const { addElementToArray, addRootElement } = useIntersectionObserver(cb, options);
  return (
    <IntersectionContext.Provider value={{ addElementToArray, addRootElement }}>
      {children}
    </IntersectionContext.Provider>
  );
};

export const useIntersection = () => useContext(IntersectionContext);
