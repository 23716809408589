'use client';

import { fixedForwardRef } from '@/ts/types';
import { ForwardedRef, ReactNode, createElement, useCallback, useEffect, useState } from 'react';
type TCollapseComponent = {
  tag?: any;
  className?: string | ((props: { collapsed: boolean }) => string);
  children?: ReactNode | ((props: { collapsed: boolean; collapse: () => void }) => ReactNode);
  visibleContent?: ReactNode | ((props: { collapsed: boolean; collapse: () => void }) => ReactNode);
  collapsed?: boolean;
} & Omit<Partial<HTMLElement>, 'children' | 'className'>;

const Collapser = (
  {
    visibleContent,
    children,
    className,
    tag,
    collapsed: _collapsed = false,
    ...props
  }: TCollapseComponent,
  ref: ForwardedRef<any>,
) => {
  const [collapsed, setCollapsed] = useState(_collapsed);

  const collapse = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  useEffect(() => {
    setCollapsed(_collapsed);
  }, [_collapsed]);

  return createElement(
    tag ?? 'div',
    {
      ref,
      className: typeof className === 'function' ? className({ collapsed }) : className,
      ...props,
    },
    typeof visibleContent === 'function' ? visibleContent({ collapse, collapsed }) : visibleContent,
    typeof children === 'function' ? children({ collapsed, collapse }) : children,
  );
};

export default fixedForwardRef(Collapser);
