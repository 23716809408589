import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/icons/arrow-with-circle-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category1.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category10.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category2.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category3.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category4.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category5.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category6.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category7.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category8.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/category/category9.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/homepage-reason1.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/homepage-reason2.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/homepage-reason3.svg");
;
import(/* webpackMode: "eager" */ "/app/public/kg/homepage-reason4.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/kg/CollapserKG.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SearchForm/SearchForm.tsx");
